
export default {
  name: 'ContentTypeListSlice',
  props: ['slice'],
  data() {
    return {
      items: this.slice.items || []
    }
  }
}
